import React from "react";
import { motion } from "framer-motion";
import "./App.css"; // Add your custom styles here

const App = () => {
  return (
    <div className="coming-soon-container">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="content"
      >
        <motion.h1
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.5 }}
          className="site-name"
        >
          Wellme Pipes
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
          className="message"
        >
          We’re crafting something amazing! Stay tuned.
        </motion.p>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, delay: 1.5 }}
          className="subscribe-form"
        >
          <input
            type="email"
            placeholder="Enter your email"
            className="email-input"
          />
          <button className="subscribe-button">Notify Me</button>
        </motion.div>
      </motion.div>

      {/* Advertisement Section */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 2 }}
        className="ad-banner"
      >
        <p className="ad-text">
          🚨 Check out our latest innovations at Wellme Pipes! Click here to
          learn more.
        </p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.7 }}
        transition={{ duration: 1.5, delay: 0.5 }}
        className="background-overlay"
      />
    </div>
  );
};

export default App;
